import adsRefs from "@/feature-ads/refs.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const originals = mapOriginalRefs({
  adsRefs,
});

export const setup = () => {
  originals.set({
    adsRefs: {
      showHeaderBanner: true,
    },
  });
};

export const teardown = () => {
  originals.restore();
};
